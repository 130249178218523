/*  */
.accordion-menu ul, .accordion-menu li {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
}
.accordion-menu li.open > ul {
    display: block;
}
.accordion-menu li > ul {
    /* position: absolute; */
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 350ms ease-in-out;
    margin-left: 25px;
}
.accordion-menu ul.is-visible {
    display: block;
    /* height: auto; */
}
.accordion-menu li {
    border-bottom: 1px solid rgba(194, 194, 194, 0.333);
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
}
.accordion-menu li:last-child {
    border: none;
}
.accordion-menu li::after {
    content: "";
    display: block;
    clear: both;
}
.accordion-menu li > p > svg {
    display: block;
    position: relative;
    fill: rgb(48, 48, 48);
    width: 10px;
    float: right;
    transition: all 0.3s ease;
}
.accordion-menu li.open > p > svg {
    transform: rotate(90deg);
}
.accordion-menu p {
    background-color: #f9f9f9;
    padding: 7px 20px;
    margin: 0px 0 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}
.accordion-menu * {
    box-sizing: border-box;
}