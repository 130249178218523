﻿.material-list {
    .body {
        overflow-y: auto !important;
        height: 275px !important;
    }

    .material-row-tab {
        display: flex;
        width: 100%; /*Optional*/
        flex-direction: row;
    }

    .material-column-tab {
        font-size: 18px;
        padding: 5px;
        width: 45%;
    }

    .material-tab-selected {
        text-decoration: underline;
    }

    .colorScheme {
        width: 50%;
        height: 40px;
        cursor: pointer;
        margin: 5px;
        border: 1px solid black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        float: left;

        &:hover,
        &.selected {
            -webkit-box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
            -moz-box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
            box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
        }
    }

    .flex-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flex-material-group {
        cursor: pointer;
    }

    .flex-img-container {
        text-align: center;
        width: 100%;
        height: 40px;
        border: 1px solid black;

        &:hover {
            -webkit-box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
            -moz-box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
            box-shadow: 0px 0px 2px 2px rgba(17, 240, 24, 1);
        }
    }

    .material-group {
        cursor: pointer;
        text-align: center;
        width: 50%;
        padding-left: 0px;
        padding-right: 0px;
    }
}
