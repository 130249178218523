/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./styles/main.scss";
 /* @tailwind base;
@tailwind components;
@tailwind utilities; 
 */
:root {
  // pb - app prefix(Plastic Bins) should be added to all variables
  --pb-toolbar-height: 8rem;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
