﻿.products-list {
    .stuff-wrapper {
        // height: 402px;
        overflow-y: auto;
    }

    .product-category-header {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #ccc;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        border-radius: 4px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: left;
        width: 100%;

        img {
            height: 35px;
        }

        .product-category-header-text {
            display: inline-block;
            margin-left: 10px;
            font-size: 18px !important;
            word-break: break-word;
        }
    }

    .first-menu-item-container {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 2px 0;
        cursor: pointer;

        &[aria-expanded="true"] .first-menu-item-switch-button img {
            transform: rotate(-90deg);
        }

        .first-menu-item-image-container {
            width: 50px;

            .first-menu-item-image {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .first-menu-item-image-container-akro {
            width: 100%;

            .first-menu-item-image {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .first-menu-item-body {
            margin-left: 10px;
        }

        .first-menu-item-switch-button {
            width: 25px;

            img {
                max-width: 100%;
                max-height: 100%;
                transform: rotate(-180deg);
            }
        }
    }

    .stuffDropDown {
        width: 100%;

        &:nth-child(2) > .dropdown-toggle > img {
            width: 100%;
            height: 100%;
        }

        ul.desktop {
            //   margin-top: 50px;
            list-style-type: none;
            padding-left: 18px;
            height: 85%;
            // overflow-y: scroll;
            overflow-y: auto;
            //max-height: 430px;
        }

        .second-menu-item {
            padding-left: 0 !important;
        }

        li {
            cursor: pointer;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin: 2px 0;
        }

        .media {
            margin: 5px;
        }

        .menuTab:hover {
            font-weight: bold;
            text-decoration: underline;
        }

        button {
            width: 100%;
        }

        button img,
        .media img {
            width: 32px;
        }

        .media .media-body {
            vertical-align: middle;
        }

        .stuffSideMenu {
            height: 70%;
            margin-top: 5px;
            // position: relative;
            img {
                width: 100px;
                margin: 0 auto;
                display: block;
                margin-top: 5px;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }

            img:hover {
                border-color: #4169e1;
            }

            .selectedItem {
                background-color: #4169e1;
            }
        }
    }
}

