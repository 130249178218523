
    .cssload-loader {
        width: 244px;
        height: 38px;
        line-height: 49px;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
        font-family: helvetica, arial, sans-serif;
        text-transform: uppercase;
        font-weight: 900;
        font-size:18px;
        color: rgb(206,66,51);
        letter-spacing: 0.2em;
    }
    .cssload-loader::before, .cssload-loader::after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: rgb(206,66,51);
        position: absolute;
        animation: cssload-load 0.81s infinite alternate ease-in-out;
            -o-animation: cssload-load 0.81s infinite alternate ease-in-out;
            -ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
            -webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
            -moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
    }
    .cssload-loader::before {
        top: 0;
    }
    .cssload-loader::after {
        bottom: 0;
    }
    
    
    
    @keyframes cssload-load {
        0% {
            left: 0;
            height: 29px;
            width: 15px;
        }
        50% {
            height: 8px;
            width: 39px;
        }
        100% {
            left: 229px;
            height: 29px;
            width: 15px;
        }
    }
    
    @-o-keyframes cssload-load {
        0% {
            left: 0;
            height: 29px;
            width: 15px;
        }
        50% {
            height: 8px;
            width: 39px;
        }
        100% {
            left: 229px;
            height: 29px;
            width: 15px;
        }
    }
    
    @-ms-keyframes cssload-load {
        0% {
            left: 0;
            height: 29px;
            width: 15px;
        }
        50% {
            height: 8px;
            width: 39px;
        }
        100% {
            left: 229px;
            height: 29px;
            width: 15px;
        }
    }
    
    @-webkit-keyframes cssload-load {
        0% {
            left: 0;
            height: 29px;
            width: 15px;
        }
        50% {
            height: 8px;
            width: 39px;
        }
        100% {
            left: 229px;
            height: 29px;
            width: 15px;
        }
    }
    
    @-moz-keyframes cssload-load {
        0% {
            left: 0;
            height: 29px;
            width: 15px;
        }
        50% {
            height: 8px;
            width: 39px;
        }
        100% {
            left: 229px;
            height: 29px;
            width: 15px;
        }
    }